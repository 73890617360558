import styled from 'styled-components';
import { Card } from '@material-ui/core';
import React from 'react';

const StyledCard = styled.span`
    background-color: white;
    background-image: url(${(props) => props.bgImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const PageTitleStyles = ({ children, bgImg = '' }) => {
    return (
        <Card>
            <StyledCard bgImg={bgImg}>{children}</StyledCard>
        </Card>
    );
};

export default PageTitleStyles;
