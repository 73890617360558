import styled from 'styled-components';

const textColor = (props) => props?.theme?.CustomTitle?.color ?? 'red';
const font = (props) => props?.theme?.CustomTitle?.fontFamily ?? 'inherit';
const fontSize = (props) => props?.theme?.CustomTitle?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.CustomTitle?.fontWeight ?? 'inherit';

const CustomTitleStyles = styled.span`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    white-space: pre-wrap;
`;

export default CustomTitleStyles;
