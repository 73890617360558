import React from 'react';
import { Typography } from '@material-ui/core';

import PageTitleStyles from './PageTitleCardStyles';
import PageTitleTextStyles from './PageTitleTextStyles';
import CardTextContainer from '../Common/CardTextContainer';
import CustomLink from '../Common/CustomLink';

const PageTitleCard = ({ linkText, bgImg, label = '', href = null }) => {
    return (
        <PageTitleStyles bgImg={bgImg}>
            <Typography variant="h5" component="h1">
                <CardTextContainer>
                    <PageTitleTextStyles>
                        {label} <CustomLink href={href}>{linkText}</CustomLink>
                    </PageTitleTextStyles>
                </CardTextContainer>
            </Typography>
        </PageTitleStyles>
    );
};

export default PageTitleCard;
