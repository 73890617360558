import CustomTitleStyles from './CustomTitleStyles';
import React from 'react';
import { Typography } from '@material-ui/core';

const CustomTitle = ({ children, align = 'left' }) => {
    return (
        <Typography align={align} variant="h5" component="h1">
            <CustomTitleStyles>{children}</CustomTitleStyles>
        </Typography>
    );
};

export default CustomTitle;
