import styled from 'styled-components';
import { CardContent } from '@material-ui/core';

const SmallCardTextContainerStyles = styled(CardContent)`
    max-width: 50rem;
    padding-top: 0.5rem;
    root: {
        '&:last-child': {
            padding-bottom: 0.5rem;
        }
    }
`;

export default SmallCardTextContainerStyles;
