import { graphql } from 'gatsby';
export const latestPostsCommonFragment = graphql`
    fragment latestPostsCommon on MarkdownRemarkConnection {
        edges {
            node {
                html
                frontmatter {
                    category
                    date(formatString: "DD MMM, YYYY")
                    title
                    themes
                    lgImage: heroImage {
                        childImageSharp {
                            ...lgImageFluid
                        }
                    }
                    smImage: heroImage {
                        childImageSharp {
                            ...smImageFluid
                        }
                    }
                }
                excerpt_long: excerpt(pruneLength: 300)
                excerpt_short: excerpt(pruneLength: 150)
                fields {
                    slug
                }
            }
        }
    }
`;

export const spreadResult = (mdr) => {
    const postsData = mdr.edges.map((edge) => {
        const {
            excerpt_long,
            excerpt_short,
            frontmatter: {
                category,
                date,
                title,
                themes,
                lgImage: {
                    childImageSharp: { fluid: lgFluid },
                },
                smImage: {
                    childImageSharp: { fluid: smFluid },
                },
            },
            fields: { slug },
        } = edge.node;
        return { excerpt_long, excerpt_short, category, date, themes, title, lgFluid, smFluid, slug };
    });

    return postsData;
};
