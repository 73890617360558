import React from 'react';

import SmallCardStyles from './SmallCardStyles';
import SmallCardTextContainer from './SmallCardTextContainer';
import CardImg from '../Common/CardImg';
import BlogText from '../Common/BlogText';
import SubTitle from '../Common/SubTitle';
import PostDate from '../Common/PostDate';
import CustomTitle from '../Common/CustomTitle';
import CustomLink from '../Common/CustomLink';
import ReadMore from '../Common/ReadMore';
import ThemeLabel from '../Common/ThemeLabel';
import { Grid } from '@material-ui/core';

const SmallCard = (props) => {
    const title = props?.title ?? '';
    const text = props?.text ?? '';
    const date = props?.date ?? '';
    const img = props?.img ?? false;
    const linkText = props.linkText ?? '';
    const linkHref = props.linkHref ?? '';
    const themes = props.themes ?? [];
    const themeBaseUrl = '/themes/';

    return (
        <SmallCardStyles>
            <Grid container alignContent="flex-start">
                <Grid item xs={12} sm={6} md={12} style={{ width: '100%' }}>
                    <CardImg alt="Illustrative image related to post" fluid={img} title={title} />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                    <SmallCardTextContainer>
                        {themes.map((el, idx) => (
                            <ThemeLabel bottomMargin="0.3rem" key={idx} href={themeBaseUrl + el}>
                                {el}
                            </ThemeLabel>
                        ))}
                        <CustomTitle align="left">{title}</CustomTitle>
                        <PostDate>{date}</PostDate>
                        <BlogText>{text}</BlogText>
                        <ReadMore align="right">
                            <SubTitle>
                                <CustomLink href={linkHref}>{linkText}</CustomLink>
                            </SubTitle>
                        </ReadMore>
                    </SmallCardTextContainer>
                </Grid>
            </Grid>
        </SmallCardStyles>
    );
};

export default SmallCard;
